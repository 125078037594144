import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    duration: Number
  }

  connect() {
    setTimeout(() => {
      this.fadeOut()
    }, this.duration)
  }

  fadeOut() {
    let opacity = 1;
    const timer = setInterval(() => {
      if (opacity <= 0.1) {
        clearInterval(timer);
        this.element.style.display = "none"
      }
      this.element.style.opacity = opacity
      opacity -= 0.1
    }, 50)
  }

  get duration() {
    return this.durationValue || 5000
  }
}