import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["menu"]
  connect() {
    document.addEventListener("click", this.handleOutsideClick.bind(this))
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this))
  }

  toggle() {
    this.menuTarget.classList.toggle('transform');
    this.menuTarget.classList.toggle('opacity-100');
    this.menuTarget.classList.toggle('scale-100');
    this.menuTarget.classList.toggle('opacity-0');
    this.menuTarget.classList.toggle('scale-95');
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target) && this.menuTarget.classList.contains('opacity-100')) {
      this.toggle();
    }
  }
}
