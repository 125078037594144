import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="layout"
export default class extends Controller {
  static values = { htmlClass: String }
  connect() {
    this.setHtmlClass()
  }

  // Turbo prevents the <html> tag from being updated, only the the <body> tag
  // is updated. TailwindUI theme relies on the class of the <html> tag to
  // correctly style the page. This method updates the <html> tag with the
  // css class that is specified as a data value on the element bound to this
  // controller, which is intended to be the <body> tag.
  setHtmlClass() {
    document.documentElement.className = this.htmlClassValue
  }
}
